import { Sales } from "api/milo-settings/models";
import { DeliveryMethodType, Order, OrderPaymentType } from "api/orders/models";
import austria from "assets/images/countries/austria.svg";
import czechRepublic from "assets/images/countries/czechRepublic.svg";
import france from "assets/images/countries/france.svg";
import germany from "assets/images/countries/germany.svg";
import hungary from "assets/images/countries/hungary.svg";
import lithuania from "assets/images/countries/lithuania.svg";
import croatia from "assets/images/countries/croatia.svg";
import estonia from "assets/images/countries/estonia.svg";
import poland from "assets/images/countries/poland.svg";
import slovakia from "assets/images/countries/slovakia.svg";
import unitedKingdom from "assets/images/countries/unitedKingdom.svg";
import ukraine from "assets/images/countries/ukraine.svg";
import romania from "assets/images/countries/romania.svg";
import italy from "assets/images/countries/italy.svg";
import latvia from "assets/images/countries/latvia.svg";
import slovenia from "assets/images/countries/slovenia.svg";
import luxembourg from "assets/images/countries/luxembourg.svg";
import ireland from "assets/images/countries/ireland.svg";
import spain from "assets/images/countries/spain.svg";
import belgium from "assets/images/countries/belgium.svg";
import netherlands from "assets/images/countries/netherlands.svg";
import europeanUnion from "assets/images/countries/europeanUnion.svg";
import bulgaria from "assets/images/countries/bulgaria.svg";
import serbia from "assets/images/countries/serbia.svg";
import denmark from "assets/images/countries/denmark.svg";
import switzerland from "assets/images/countries/switzerland.svg";
import norway from "assets/images/countries/norway.svg";
import sweden from "assets/images/countries/sweden.svg";
import finland from "assets/images/countries/finland.svg";
import albania from "assets/images/countries/albania.svg";
import belarus from "assets/images/countries/belarus.svg";
import greece from "assets/images/countries/greece.svg";
import kazakhstan from "assets/images/countries/kazakhstan.svg";
import call from "assets/images/call.svg";
import phone_forwarded_teal from "assets/images/phone_forwarded_teal.svg";
import phone_in_talk from "assets/images/phone_in_talk.svg";
import check_small from "assets/images/check_small.svg";
import forward_to_inbox from "assets/images/forward_to_inbox.svg";
import unread from "assets/images/unread.svg";
import awaits from "assets/images/awaits.svg";
import inprogress from "assets/images/inprogress.svg";
import done from "assets/images/done.svg";
import { ProductionPlanStatus } from "api/new-production-plans/models";
import { EmploymentType } from "api/manufacturingNew/models";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { BasePaymentType } from "api/types";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { User } from "api/users/models";
import empikIcon from "assets/images/marketplaces/empik.svg";
import meble24Icon from "assets/images/marketplaces/meble24.svg";
import xxxLutzIcon from "assets/images/marketplaces/xxxLutz.svg";
import blackRedWhiteIcon from "assets/images/marketplaces/blackRedWhite.svg";
import kauflandIcon from "assets/images/marketplaces/kaufland.svg";
import ottoIcon from "assets/images/marketplaces/otto.svg";
import prestaShopIcon from "assets/images/marketplaces/prestaShop.svg";
import moreleIcon from "assets/images/marketplaces/morele.svg";
import allegroIcon from "assets/images/marketplaces/allegro.svg";
import home24Icon from "assets/images/marketplaces/home24.svg";
import amazonIcon from "assets/images/marketplaces/amazon.svg";
import ebayIcon from "assets/images/marketplaces/ebay.svg";
import shopifyIcon from "assets/images/marketplaces/shopify.svg";
import hoodIcon from "assets/images/marketplaces/hood.svg";
import idosellIcon from "assets/images/marketplaces/idosell.svg";
import manomanoIcon from "assets/images/marketplaces/manomano.svg";
import erliIcon from "assets/images/marketplaces/erli.svg";
import virtuemartIcon from "assets/images/marketplaces/virtuemart.svg";
import baselinkerIcon from "assets/images/marketplaces/baselinker.svg";
import shoperIcon from "assets/images/marketplaces/shoper.svg";
import arenaIcon from "assets/images/marketplaces/arena.svg";
import wooCommerceIcon from "assets/images/marketplaces/wooCommerce.svg";
import maisonsDuModne from "assets/images/marketplaces/maisonsDuModne.svg";
import but from "assets/images/marketplaces/but.svg";
import venteUnique from "assets/images/marketplaces/venteUnique.svg";
import wayfair from "assets/images/marketplaces/wayfair.svg";
import { dictToList } from "utilities";

export const PRODUCT_TYPES = [
  { name: "skrzyniowe", id: "box" },
  { name: "tapicerowane", id: "upholstery" },
  { name: "inne", id: "other" },
  { name: "zestawy", id: "set" },
];

export type Channels =
  | "allegro"
  | "amazon"
  | "arena"
  | "brw"
  | "b2b"
  | "ebay"
  | "but"
  | "vente_unique"
  | "empik"
  | "erli"
  | "presta"
  | "shoper"
  | "manomano"
  | "moebel24"
  | "morele"
  | "ottode"
  | "virtuemart"
  | "wayfair"
  | "baselinker"
  | "kaufland"
  | "idosell"
  | "shopify"
  | "woocommerce"
  | "woocommerce_standard"
  | "hood"
  | "home24"
  | "xxxlutz"
  | "maisons_du_monde"
  | "online-store"
  | "other";

export const availablePaymentTypesDict: Record<OrderPaymentType, string> = {
  ONLINE: "Przelew",
  CASH_ON_DELIVERY: "Gotówka",
  DEBIT_CARD: "Karta",
  INSTALMENT: "Raty",
};

export const paymentStatusDict: Record<Order["payment"]["status"], string> = {
  PAID: "Opłacono",
  OVERPAID: "Przepłacono",
  PARTIALLY_PAID: "Opłacono częściowo",
  UNPAID: "Nie opłacono",
};

export type StatusKind = "ORDER" | "ORDER_GROUP" | "ROUTE";

export type StatusType =
  | "CANCELED"
  | "CONFIRMED"
  | "DELIVERED"
  | "DEPARTED"
  | "FINISHED"
  | "GROUP_READY"
  | "NEW"
  | "ON_ROUTE"
  | "PROCESSING"
  | "PRODUCTION_COMPLETE"
  | "RECEIVED"
  | "RETURNED"
  | "ROUTE_READY"
  | "DELIVERY_CONFIRMED"
  | "SETTLED";

export const statusTypeDict: Record<StatusType, string> = {
  CANCELED: "Anulowane",
  CONFIRMED: "Potwierdzone",
  DELIVERED: "Odebrane",
  DEPARTED: "W drodze",
  FINISHED: "Zakończone",
  GROUP_READY: "Grupa gotowa",
  NEW: "Nowe",
  ON_ROUTE: "Na trasie",
  PROCESSING: "W trakcie przetwarzania",
  RECEIVED: "Otrzymane",
  RETURNED: "Zwrot do nadawcy",
  ROUTE_READY: "Gotowe do wysyłki",
  DELIVERY_CONFIRMED: "Potwierdzona dostawa",
  SETTLED: "Rozliczone",
  PRODUCTION_COMPLETE: "Wyprodukowano",
};

export const statusKindDict: Record<StatusKind, string> = {
  ORDER: "Zamówienie",
  ORDER_GROUP: "Grupa zamówień",
  ROUTE: "Trasa",
};

export type ColorPaletteBackground = keyof typeof colorPaletteTokens;
export type ColorPaletteTextColor = typeof colorPaletteTokens[ColorPaletteBackground];

export const colorPaletteTokens = {
  "#fdb4c0": "#333",
  "#ffc0e5": "#333",
  "#fdf1bb": "#333",
  "#f9fdbb": "#333",
  "#f3fdbb": "#333",
  "#defdbb": "#333",
  "#d0fdbb": "#333",
  "#c3fdbb": "#333",
  "#bbfdc9": "#333",
  "#bbfdde": "#333",
  "#bbfdf4": "#333",
  "#bbfdfd": "#333",
  "#bbeefd": "#333",
  "#bbdffd": "#333",
  "#bbd8fd": "#333",
  "#bbd0fd": "#333",
  "#c5bbfd": "#333",
  "#d4bbfd": "#333",
  "#e8bbfd": "#333",
  "#ffcf9d": "#333",
  "#ff867d": "#fff",
  "#e37c8c": "#333",
  "#d97ab6": "#333",
  "#cfba5e": "#333",
  "#dfd458": "#333",
  "#c4d149": "#333",
  "#b0d776": "#333",
  "#8fd16b": "#333",
  "#8be382": "#333",
  "#6de5ad": "#333",
  "#7ecfaf": "#333",
  "#65d7cc": "#333",
  "#5cc2c2": "#333",
  "#60c4df": "#333",
  "#75a9d7": "#333",
  "#4d80c4": "#fff",
  "#5a3fb5": "#fff",
  "#8577d1": "#fff",
  "#aa4cc8": "#fff",
  "#c36bc4": "#fff",
  "#e59b5c": "#333",
  "#e5615c": "#333",
  "#be364b": "#fff",
  "#cf3798": "#fff",
  "#dfc13c": "#333",
  "#f1df13": "#333",
  "#d1e328": "#333",
  "#9ee337": "#333",
  "#71dd36": "#333",
  "#40d331": "#333",
  "#22c278": "#333",
  "#28ddcc": "#333",
  "#0ec8c0": "#fff",
  "#1c9f9f": "#fff",
  "#33879d": "#fff",
  "#2b8fe9": "#fff",
  "#187bff": "#fff",
  "#455fc0": "#fff",
  "#de6ff1": "#333",
  "#ff00e0": "#333",
  "#e95d88": "#fff",
  "#f1862d": "#333",
  "#e76640": "#333",
  "#98aeba": "#333",
  "#eee": "#333",
  "#999": "#333",
  "#666": "#fff",
  "#333": "#fff",
  "#111": "#fff",
  "#2bc4e0": "#333",
} as const;

export const FLAVOR = (() => {
  const flavor = process.env.REACT_APP_FLAVOR;
  if (
    flavor !== "main" &&
    flavor !== "b2b" &&
    flavor !== "manufacturing" &&
    flavor !== "externalManufacturing"
  ) {
    throw new Error(
      "Provided flavor is not valid! It should be one of [main, b2b, manufacturing, externalManufacturing]",
    );
  }
  return flavor as "main" | "b2b" | "manufacturing" | "externalManufacturing";
})();

export const signatureSchemaTags = [
  { name: "{month}", value: "miesiąc" },
  { name: "{year}", value: "rok" },
  { name: "{country_code}", value: "kod kraju" },
  { name: "{counter}", value: "licznik" },
];

export const emailMessageTags = [
  { name: "{{order_tracking_number}}", value: "numer śledzenia" },
  { name: "{{tracking_link}}", value: "link do śledzenia zamówienia" },
  { name: "{{earliest_delivery_hour}}", value: "minimalna data dostawy" },
  { name: "{{latest_delivery_hour}}", value: "maksymalna data dostawy" },
  { name: "{{order_number}}", value: "numer zamówienia" },
];

export const smsMessageRouteTags = [
  { name: "{nr kierowcy}", value: "numer telefonu do kierowcy" },
  { name: "{data dostawy}", value: "przewidywany czas dostawy" },
  { name: "{min godz dostawy}", value: "minimalna godzina dostawy" },
  { name: "{max godz dostawy}", value: "maksymalna godzina dostawy" },
  { name: "{link}", value: "link do śledzenia zamówienia" },
  { name: "{nazwa kontrahenta}", value: "nazwa kontrahenta" },
  { name: "{imię klienta}", value: "imię klienta" },
  { name: "{kwota pobrania}", value: "kwota pobrania" },
  { name: "{relatywny limit potwierdzenia}", value: "relatywny limit potwierdzenia" },
  { name: "{absolutny limit potwierdzenia}", value: "absolutny limit potwierdzenia" },
];

export const smsMessageOrderStatusChangeTags = [
  { name: "{status}", value: "status" },
  { name: "{link do zamówienia}", value: "link do śledzenia zamówienia" },
  { name: "{numer zamówienia}", value: "numer zamówienia" },
  { name: "{kwota pobrania}", value: "kwota pobrania" },
];

export const smsMessageDeliveryDateConfirmed = [
  { name: "{min godz dostawy}", value: "minimalna godzina dostawy" },
  { name: "{max godz dostawy}", value: "maksymalna godzina dostawy" },
  { name: "{planowana godz dostawy}", value: "planowana godz dostawy" },
];

export const smsMessageRequestForCustomerConfirmationTags = [
  { name: "{id zamówienia}", value: "ID zamówienia" },
  { name: "{zew numer zamówienia}", value: "zewnętrzny numer zamówienia" },
  { name: "{sygnatura zamówienia}", value: "sygnatura zamówienia" },
  { name: "{zamówione produkty}", value: "nazwy produktów z zamówienia" },
  { name: "{adres}", value: "adres dostawy z zamówienia" },
  { name: "{cena towaru i transportu}", value: "cena towaru i transportu" },
  { name: "{wniesienie}", value: "wniesienie" },
  { name: "{link do śledzenia}", value: "link do śledzenia" },
  { name: "{numer śledzenia}", value: "numer śledzenia" },
  { name: "{id reklamacji}", value: "ID reklamacji" },
  { name: "{zew numer reklamacji}", value: "zewnętrzny numer reklamacji" },
  { name: "{sygnatura reklamacji}", value: "sygnatura reklamacji" },
];

export const polishMonthsAbbreviations: string[] = [
  "sty",
  "lut",
  "mar",
  "kwi",
  "maj",
  "cze",
  "lip",
  "sie",
  "wrz",
  "paź",
  "lis",
  "gru",
];

export type EmployeeKind = "UPHOLSTERER" | "CUTTER" | "SEAMSTRESS";

// for new manufacturing view we use more kinds of employees
export type EmployeeKindNew = "CARPENTER" | "PACKER" | "WAREHOUSEMAN" | "FOAMER" | "MATTRESSER";
export type EmployeeKindExtended = EmployeeKind | EmployeeKindNew;

export const employeeKindConfigDict: Record<
  EmployeeKindExtended,
  { name: string; color: string; backgroundColor: string }
> = {
  UPHOLSTERER: { name: "TAPICER", color: "#508b46", backgroundColor: "rgba(103, 172, 91, 0.35)" },
  CUTTER: { name: "KROJCZY", color: "#a18200", backgroundColor: "rgba(246, 198, 2, 0.35)" },
  SEAMSTRESS: { name: "KRAWCOWA", color: "#a18200", backgroundColor: "rgba(246, 198, 2, 0.35)" },
  CARPENTER: { name: "STOLARZ", color: "#00939c", backgroundColor: "rgba(1, 201, 213, 0.35)" },
  PACKER: { name: "PAKOWACZ", color: "#33776b", backgroundColor: "rgba(65, 148, 136, 0.35)" },
  WAREHOUSEMAN: { name: "MAGAZYNIER", color: "#a18200", backgroundColor: "#f6e8ad" },
  FOAMER: {
    name: "PIANKUJĄCY",
    color: "rgba(58, 91, 13, 1)",
    backgroundColor: "rgba(135, 212, 126, 1)",
  },
  MATTRESSER: {
    name: "MATERACOWY",
    color: colorPalette.blue600,
    backgroundColor: "rgba(42, 131, 225, 1)",
  },
};

export const employmentTypeColorDict: Record<
  EmploymentType,
  { color: string; backgroundColor: string }
> = {
  INTERNAL: { color: "#fff", backgroundColor: "#8c73ff" },
  EXTERNAL: { color: "#333", backgroundColor: "#d0db59" },
};

export const upholsteriesEmployeesKind: Record<
  EmployeeKindExtended,
  { name: string; color: string }
> = {
  UPHOLSTERER: {
    name: "Tapicer",
    color: "rgb(254, 158, 118)",
  },
  CUTTER: {
    name: "Krojczy",
    color: "#D4CD03",
  },
  SEAMSTRESS: {
    name: "Krawiec",
    color: "#01c9d5",
  },
  CARPENTER: {
    name: "Stolarz",
    color: "#00939c",
  },
  PACKER: {
    name: "Pakowacz",
    color: "#33776b",
  },
  WAREHOUSEMAN: {
    name: "Magazynier",
    color: "#a18200",
  },
  FOAMER: {
    name: "Piankujący",
    color: "rgba(58, 91, 13, 1)",
  },
  MATTRESSER: {
    name: "Materacowy",
    color: colorPalette.blue600,
  },
};

export const channels: Record<Channels, { name: string; color: string; icon: string }> = {
  allegro: {
    name: "Allegro",
    color: "#FE5900",
    icon: allegroIcon,
  },
  amazon: {
    name: "Amazon",
    color: "#F9A51B",
    icon: amazonIcon,
  },
  arena: {
    name: "Arena",
    color: "#312682",
    icon: arenaIcon,
  },
  baselinker: {
    name: "Baselinker",
    color: "#3F87F4",
    icon: baselinkerIcon,
  },
  brw: {
    name: "BRW",
    color: "#D41319",
    icon: blackRedWhiteIcon,
  },
  b2b: {
    name: "b2b",
    color: "#FF5A00",
    icon: "",
  },
  ebay: {
    name: "eBay",
    color: "#F2CB01",
    icon: ebayIcon,
  },
  erli: {
    name: "Erli",
    color: "#0797BB",
    icon: erliIcon,
  },
  empik: {
    name: "Empik",
    color: "#2B2724",
    icon: empikIcon,
  },
  home24: {
    name: "Home24",
    color: "#F45334",
    icon: home24Icon,
  },
  hood: {
    name: "Hood",
    color: "#82C905",
    icon: hoodIcon,
  },
  idosell: {
    name: "Idosell",
    color: "#1CAD7C",
    icon: idosellIcon,
  },
  kaufland: {
    name: "Kaufland",
    color: "#E31E24",
    icon: kauflandIcon,
  },
  moebel24: {
    name: "Moebel24",
    color: "#650000",
    icon: meble24Icon,
  },
  morele: {
    name: "Morele",
    color: "#FF5B4D",
    icon: moreleIcon,
  },
  manomano: {
    name: "ManoMano",
    color: "#01EACA",
    icon: manomanoIcon,
  },
  "online-store": {
    name: "Sklep internetowy",
    color: colorPalette.blue600,
    icon: "",
  },
  ottode: {
    name: "Otto.de",
    color: "#E34056",
    icon: ottoIcon,
  },
  presta: {
    name: "Presta",
    color: "#DE0168",
    icon: prestaShopIcon,
  },
  shoper: {
    name: "Shoper",
    color: "#002FFF",
    icon: shoperIcon,
  },
  shopify: {
    name: "Shopify",
    color: "#95BC45",
    icon: shopifyIcon,
  },
  wayfair: {
    name: "Wayfair",
    color: "#975EA1",
    icon: wayfair,
  },
  woocommerce: {
    name: "Woocommerce",
    color: "#7F54B3",
    icon: wooCommerceIcon,
  },
  woocommerce_standard: {
    name: "Woocommerce standard",
    color: "#7F54B3",
    icon: wooCommerceIcon,
  },
  xxxlutz: {
    name: "XXXLutz",
    color: "#E40013",
    icon: xxxLutzIcon,
  },
  virtuemart: {
    name: "Virtuemart",
    color: "#00A8FF",
    icon: virtuemartIcon,
  },
  maisons_du_monde: {
    name: "Maisons du monde",
    color: colorPalette.neutralBlack88,
    icon: maisonsDuModne,
  },
  but: {
    name: "But",
    color: colorPalette.neutralBlack88,
    icon: but,
  },
  vente_unique: {
    name: "Vente unique",
    color: colorPalette.neutralBlack88,
    icon: venteUnique,
  },
  other: {
    name: "Inne",
    color: colorPalette.neutralBlack100,
    icon: "",
  },
};

export const CHANNELS = dictToList(channels).map(({ key, value }) => ({
  id: key,
  name: value.name,
}));

export const COUNTRIES = [
  { name: "Polska" },
  { name: "Niemcy" },
  { name: "Holandia" },
  { name: "Francja" },
  { name: "Belgia" },
  { name: "Rosja" },
  { name: "Białoruś" },
  { name: "Litwa" },
  { name: "Włochy" },
  { name: "Łotwa" },
  { name: "Słowenia" },
  { name: "Ukraina" },
  { name: "Węgry" },
  { name: "Słowacja" },
  { name: "Czechy" },
  { name: "Austria" },
  { name: "Rumunia" },
  { name: "Wielka Brytania" },
  { name: "Hiszpania" },
  { name: "Irlandia" },
  { name: "Łotwa" },
];
export const CURRENCY_DICT = {
  PLN: "zł",
  USD: "$",
  EUR: "€",
};

export const polishDaysNamesDict = {
  MONDAY: "Poniedziałek",
  TUESDAY: "Wtorek",
  WEDNESDAY: "Środa",
  THURSDAY: "Czwartek",
  FRIDAY: "Piątek",
  SATURDAY: "Sobota",
  SUNDAY: "Niedziela",
};

export const MINIMUM_PRODUCTION_PLAN_SHELF_HEIGHT = 53;

export type CountryCode = "PL" | "DE" | "CZ" | "SK" | "FR" | "UK" | "HU" | "UA" | "AT" | "LT";

export const countryCodes: { value: CountryCode | string; icon: string; name: string }[] = [
  { value: "PL", icon: poland, name: "Polska" },
  { value: "CZ", icon: czechRepublic, name: "Czechy" },
  { value: "SK", icon: slovakia, name: "Słowacja" },
  { value: "DE", icon: germany, name: "Niemcy" },
  { value: "HU", icon: hungary, name: "Węgry" },
  { value: "LT", icon: lithuania, name: "Litwa" },
  { value: "AT", icon: austria, name: "Austria" },
  { value: "GB", icon: unitedKingdom, name: "Wielka Brytania" },
  { value: "FR", icon: france, name: "Francja" },
  { value: "UA", icon: ukraine, name: "Ukraina" },
  { value: "LU", icon: luxembourg, name: "Luksemburg" },
  { value: "IE", icon: ireland, name: "Irlandia" },
  { value: "ES", icon: spain, name: "Hiszpania" },
  { value: "BE", icon: belgium, name: "Belgia" },
  { value: "NL", icon: netherlands, name: "Holandia" },
  { value: "RO", icon: romania, name: "Rumunia" },
  { value: "IT", icon: italy, name: "Włochy" },
  { value: "LV", icon: latvia, name: "Łotwa" },
  { value: "SI", icon: slovenia, name: "Słowenia" },
  { value: "HR", icon: croatia, name: "Chorwacja" },
  { value: "BG", icon: bulgaria, name: "Bułgaria" },
  { value: "RS", icon: serbia, name: "Serbia" },
  { value: "EE", icon: estonia, name: "Estonia" },
  { value: "DK", icon: denmark, name: "Dania" },
  { value: "CH", icon: switzerland, name: "Szwajcaria" },
  { value: "NO", icon: norway, name: "Norwegia" },
  { value: "SE", icon: sweden, name: "Szwecja" },
  { value: "FI", icon: finland, name: "Finlandia" },
  { value: "AL", icon: albania, name: "Albania" },
  { value: "BY", icon: belarus, name: "Białoruś" },
  { value: "GR", icon: greece, name: "Grecja" },
  { value: "KZ", icon: kazakhstan, name: "Kazachstan" },
];

export const smsCountryDict: Record<CountryCode | string, string> = {
  PL: "Polska",
  DE: "Niemcy",
  GB: "Wielka Brytania",
  UK: "Wielka Brytania",
  FR: "Francja",
  CZ: "Czechy",
  SK: "Słowacja",
  RO: "Rumunia",
  NL: "Holandia",
  BE: "Belgia",
  AT: "Austria",
};

export const languages: { value: CountryCode | string; language: string }[] = Object.entries(
  smsCountryDict,
).map(([value, language]) => ({
  value,
  language,
}));

export const countryCurrenciesFlags: Record<CURRENCY_TYPE | string, string> = {
  PLN: poland,
  CZK: czechRepublic,
  HUF: hungary,
  GBP: unitedKingdom,
  RON: romania,
  EUR: europeanUnion,
};

export const paymentTypeToTranslationDict: Record<BasePaymentType, string> = {
  ONLINE: "Przelew",
  CASH: "Gotówka",
  CASH_ON_DELIVERY: "Gotówka",
  DEBIT_CARD: "Karta",
  INSTALMENT: "Raty",
  ON_DELIVERY: "Pobranie",
  PAYMENT_INTO_ACCOUNT: "Płatność na konto",
  SPLIT_PAYMENT: "Podzielona płatność",
  WIRE_TRANSFER: "Przelew",
};

export type CURRENCY_TYPE = "PLN" | "EUR" | "GBP" | "HUF" | "DKK" | "CZK" | "RON";

const currency: Record<CURRENCY_TYPE, string> = {
  CZK: "CZK",
  DKK: "DKK",
  EUR: "EUR",
  GBP: "GBP",
  HUF: "HUF",
  PLN: "PLN",
  RON: "RON",
};

export const currenciesToPick = Object.entries(currency).map(([value, label]) => ({
  label,
  value,
}));

export const currenciesOptions = Object.entries(currency).map(([value, name]) => ({
  value,
  text: name,
  type: MenuItemType.TEXT,
}));

export const ORDER_WARRANTY_STATUS = [
  { id: "WITH_WARRANTY", name: "Na gwarancji" },
  { id: "WITHOUT_WARRANTY", name: "Po gwarancji" },
];

export const ORDER_KIND = [
  { id: "REPAIR", name: "Naprawa" },
  { id: "SUPPLEMENT", name: "Uzupełnienie" },
  { id: "REPLACEMENT", name: "Wymiana" },
  { id: "RESIGNATION", name: "Rezygnacja" },
];
export const CURRENCY = [
  { id: "PLN", name: "PLN" },
  { id: "EUR", name: "EUR" },
  { id: "GBP", name: "GBP" },
  { id: "HUF", name: "HUF" },
  { id: "DKK", name: "DKK" },
  { id: "CZK", name: "CZK" },
  { id: "RON", name: "RON" },
];

export const SHIPMENT_LABEL_TYPES = (() => {
  enum ShipmentLabels {
    waybill = "waybill",
    blpLabel = "blp_label",
    blpLabelInPdfFormat = "blp_label_in_pdf_format",
    blpLabelInZebraFormat = "blp_label_in_zebra_format",
  }
  return [
    { id: ShipmentLabels.waybill, name: "List przewozowy" },
    { id: ShipmentLabels.blpLabel, name: "Etykieta BLP" },
    { id: ShipmentLabels.blpLabelInPdfFormat, name: "Etykieta BLP w formacie PDF" },
    {
      id: ShipmentLabels.blpLabelInZebraFormat,
      name: "Etykieta BLP w formacie Zebra",
    },
  ];
})();

export const SHIPMENT_PACKAGE_TYPES = (() => {
  enum Names {
    package = "package",
    envelope = "envelope",
    pallet = "pallet",
  }
  return {
    list: [
      { id: Names.package, name: "paczka" },
      { id: Names.envelope, name: "koperta" },
      { id: Names.pallet, name: "paleta" },
    ],
    enum: { [Names.package]: "paczka", [Names.envelope]: "koperta", [Names.pallet]: "paleta" },
  };
})();

export const SHIPMENT_PAYMENT_TYPES = (() => {
  enum Names {
    receiver = "receiver",
    shipper = "shipper",
  }
  return {
    list: [
      { id: Names.receiver, name: "odbiorca" },
      { id: Names.shipper, name: "nadawca" },
    ],
    enum: { [Names.receiver]: "odbiorca", [Names.shipper]: "nadawca" },
  };
})();

export const GOOGLE_MAPS_API_KEY =
  process.env.NODE_ENV === "development"
    ? "AIzaSyBONyztKzJ-UVICkvo8BtaoW-eAqnhnSQA"
    : "AIzaSyC3U3geO4uRnaiSNgvFZvlIstURkm8Myew";

export const GOOGLE_MAPS_STATIC_KEY =
  process.env.NODE_ENV === "development"
    ? "AIzaSyBONyztKzJ-UVICkvo8BtaoW-eAqnhnSQA"
    : "AIzaSyC3U3geO4uRnaiSNgvFZvlIstURkm8Myew";

export const GOOGLE_MAPS_GEOCODE_KEY =
  process.env.NODE_ENV === "development"
    ? "AIzaSyBONyztKzJ-UVICkvo8BtaoW-eAqnhnSQA"
    : "AIzaSyC3U3geO4uRnaiSNgvFZvlIstURkm8Myew";

export const GRAPHHOPPER_KEY = "4fd347ab-686f-45b7-b367-4440d49a37a2";

export const POLAND_CENTER = {
  lat: 52.2249801,
  lng: 19.0526493,
};

// this number is based on response time, if we add more timeout might occur
export const MAX_NUMBER_OF_ORDERS_TO_MULTIPLE_ADD = 50;

export const deliveryMethodDict: Record<string, { id: DeliveryMethodType; name: string }> = {
  external: { id: "external-shipment", name: "transport zewnętrzny" },
  own: {
    id: "self-shipped",
    name: FLAVOR === "main" ? "transport własny" : "transport producenta",
  },
  personalCollection: { id: "personal-collection", name: "odbiór osobisty" },
};

export const paymentTypeDict: Record<keyof Sales["paymentType"], OrderPaymentType> = {
  cashOnDelivery: "CASH_ON_DELIVERY",
  debitCard: "DEBIT_CARD",
  installment: "INSTALMENT",
  online: "ONLINE",
};

export const deliveryMethodEnum = {
  "self-shipped": "Własny",
  "external-shipment": "Zewnętrzny",
  "personal-collection": "Odbiór osobisty",
};

type CALL_STATUS_TYPE = "CALLED" | "NO_CALL_YET" | "ON_A_CALL";
type BOOL_STRING = "true" | "false";

export const callStatusFiltersToPick: { value: CALL_STATUS_TYPE; icon: string }[] = [
  { value: "NO_CALL_YET", icon: call },
  { value: "CALLED", icon: phone_forwarded_teal },
  { value: "ON_A_CALL", icon: phone_in_talk },
];

export const smsConfirmedStatusFiltersToPick: {
  label: string;
  value: BOOL_STRING;
  icon: string;
}[] = [
  { label: "potwierdzono", value: "true", icon: check_small },
  { label: "niepotwierdzono", value: "false", icon: phone_in_talk },
];

export const smsSentStatusFiltersToPick: { label: string; value: BOOL_STRING; icon: string }[] = [
  { label: "wysłano", value: "true", icon: forward_to_inbox },
  { label: "nie wysłano wiad.", value: "false", icon: unread },
];

export type DayOfTheWeek =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";

export const daysOfTheWeek = {
  MONDAY: "Poniedziałek",
  TUESDAY: "Wtorek",
  WEDNESDAY: "Środa",
  THURSDAY: "Czwartek",
  FRIDAY: "Piątek",
  SATURDAY: "Sobota",
  SUNDAY: "Niedziela",
};

export const daysOfTheWeekConjugated = {
  MONDAY: "W poniedziałek",
  TUESDAY: "We wtorek",
  WEDNESDAY: "W środę",
  THURSDAY: "W czwartek",
  FRIDAY: "W piątek",
  SATURDAY: "W sobotę",
  SUNDAY: "W niedzielę",
};

type StatusTypes = ProductionPlanStatus;

export const statusIconDictionary: Record<StatusTypes, string> = {
  NOT_STARTED: awaits,
  IN_PROGRESS: inprogress,
  FINISHED: done,
};

export const permittedUserTypes: User["type"][] = [
  "client",
  "developer",
  "standard",
  "employee",
  "warehouseman",
];
